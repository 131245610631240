import './index.css';
import { useContext } from 'react';
import { BalContext } from '../../BalContext';

export default function Footer() {
  const balSettings = useContext(BalContext);

  return (
    <div className='Sponsors'>
      { balSettings && balSettings.sponsorImages && balSettings.sponsorImages.map((image, index) => (
        <a key={ index } href={ image.hRef }><img src={ image.url } width='100px' alt={ image.alt } /></a>
      ))}
    </div>
  );
}