import * as React from 'react';
import { getTokenFromLocalStorage } from '../../../Common';
import { toast } from 'react-hot-toast';
import './index.css';
import { useContext } from 'react';
import { BalContext } from '../../../BalContext';

const Images = () => {
  const [images, setImages] = React.useState([]);
  const [imagesToUpload, setImagesToUpload] = React.useState();
  const [mainImage, setMainImage] = React.useState(0);
  const [sponsorImages, setSponsorImages] = React.useState([]);
  const [mainAlt, setMainAlt] = React.useState();
  const [sponsorAlts, setSponsorAlts] = React.useState([]);
  const [sponsorHRefs, setSponsorHRefs] = React.useState([]);
  const balSettings = useContext(BalContext);

  React.useEffect(() => {
    getImages();
  }, []);

  React.useEffect(() => {
    if (balSettings) {
      setMainAlt(balSettings.mainImage.alt);
      setSponsorAlts(balSettings.sponsorImages.map(x => x.alt));
      setSponsorHRefs(balSettings.sponsorImages.map(x => x.hRef));
      setMainImage(images.findIndex(x => x === balSettings.mainImage.url));
      setSponsorImages(balSettings.sponsorImages.map(s => images.findIndex(x => x === s.url)));
    }
  }, [images, balSettings]);

  const uploadImages = () => {
    postImages();
  }

  const setSponsorAltsAt = (i, value) => {
    let copiedSponsorAlts = structuredClone(sponsorAlts);
    copiedSponsorAlts[i] = value;
    setSponsorAlts(copiedSponsorAlts);
  }


  const setSponsorHRefsAt = (i, value) => {
    let copiedSponsorHRefs = structuredClone(sponsorHRefs);
    copiedSponsorHRefs[i] = value;
    setSponsorHRefs(copiedSponsorHRefs);
  }


  const setSponsorImageAt = (i, value) => {
    let copiedSponsors = structuredClone(sponsorImages);
    copiedSponsors[i] = value;
    setSponsorImages(copiedSponsors);
  }

  const updateSponsorImages = async () => {
    var sponsorBody = [];
    for (let index = 0; index < sponsorImages.length; index++) {
      sponsorBody.push({
        url: images[sponsorImages[index]],
        hRef: sponsorHRefs[index],
        alt: sponsorAlts[index],
      });
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/UpdateSponsorImages`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify(sponsorBody)
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när bilder skulle uppdateras");
        console.log('Something went wrong when updating images: ', response);
        return;
      }
      else {
        toast.success("Sponsorbilder ändrade! Ladda gärna om sidan");
      }
    }
    catch (err) {
      console.log('Something went wrong when updating images: ', err);
    }
    finally {
    }
  };

  const updateMainImage = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/UpdateMainImage`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify({
          url: images[mainImage],
          alt: mainAlt
        })
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när bilder skulle uppdateras");
        console.log('Something went wrong when updating images: ', response);
        return;
      }
      else {
        toast.success("Huvudbild ändrad! Ladda gärna om sidan");
      }
    }
    catch (err) {
      console.log('Something went wrong when updating images: ', err);
    }
    finally {
    }
  };

  const getImages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/GetAllImages`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel bilder skulle hämtas");
        console.log('Something went wrong when getting all images: ', response);
        return;
      }
      else {
        setImages(await response.json());
      }
    }
    catch (err) {
      console.log('Something went wrong when getting all images: ', err);
    }
    finally {
    }
  };

  const deleteImage = async (image) => {
    var filename = image.split('/').pop();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/DeleteImage?blobName=${filename}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel bilden skulle tas bort");
        console.log('Something went wrong when deleting image: ', response);
        return;
      }
      else {
        toast.success("Bild borttagen");
        getImages();
      }
    }
    catch (err) {
      console.log('Something went wrong when deleting image: ', err);
    }
    finally {
    }
  };

  const postImages = async () => {
    try {
      let formData = new FormData();
      for (let i = 0; i < imagesToUpload.length; i++) {
        formData.append('File', imagesToUpload[i]);
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/UploadImage`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: formData
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel bilder skulle laddas upp");
        console.log('Something went wrong when uploading images: ', response);
        return;
      }
      else {
        toast.success("Bilder uppladdade!");
        getImages();
      }
    }
    catch (err) {
      console.log('Something went wrong when uploading images: ', err);
    }
    finally {
    }
  };

  const setImagesFromForm = (e) => {
    e.preventDefault();
    setImagesToUpload(e.target.files);
  }

  return (
    <div>
      <h3>Bilder att välja mellan</h3>
      <div className='imagesContainer'>
        {images && images.map((image, index) => (
          <div className='imageWithButton'>
            <img className='image' key={index} src={image} width='150px' alt='main to select' />
            <button onClick={() => deleteImage(image)}>Ta bort</button>
          </div>
        ))}
      </div>
      <h3>Ladda upp nya bilder</h3>
      <div className='uploadContainer'>
        <form>
          <input
            type="file"
            multiple={true}
            name="importImages"
            id="importImages"
            onChange={setImagesFromForm}
          />
        </form>
        <p>Max 10MB per bild och bara bilder.</p>
        <button onClick={uploadImages}>Ladda upp</button>
      </div>
      <h3>Välj huvudbild</h3>
      <div className='mainImageContainer'>
        <div className='imageWithButton'>
          <img src={images[mainImage]} width='400px' alt='main to select' />
          <div className='mainImageWithButton'>
            <button className='mainImageWithButtonButton' onClick={() => setMainImage(mainImage - 1 < 0 ? images.length - 1 : mainImage - 1)}>←</button>
            <button className='mainImageWithButtonButton' onClick={() => setMainImage(mainImage + 1 >= images.length ? 0 : mainImage + 1)}>→</button>
          </div>
        </div>
        <label for="mainAlt" className='imageInputLabel'>Alt text:</label>
        <input
          className='imageInputLabel'
          id='mainAlt'
          type="text"
          value={mainAlt}
          onChange={(e) => { setMainAlt(e.target.value); }}
        />
        <button onClick={updateMainImage}>Spara</button>
      </div>
      <h3>Välj sponsorbilder</h3>
      <div className='sponsorImagesContainer'>
        {sponsorImages && sponsorImages.map((image, index) => (
          <div>
            <div className='imageWithButton'>
              <img key={index} src={images[image]} width='150px' alt='sponsor to select' />
              <div className='mainImageWithButton'>
                <button className='sponsorImagesContainerButton' onClick={() => setSponsorImageAt(index, image - 1 < 0 ? images.length - 1 : image - 1)}>←</button>
                <button className='sponsorImagesContainerButton' onClick={() => setSponsorImageAt(index, image + 1 >= images.length ? 0 : image + 1)}>→</button>
              </div>
            </div>
            <label for={`sponsorAlt${index}`} className='imageInputLabel'>Alt text:</label>
            <input
              className='imageInputLabel'
              id={`sponsorAlt${index}`}
              type="text"
              value={sponsorAlts[index]}
              onChange={(e) => { setSponsorAltsAt(index, e.target.value); }}
            />
            <label for={`sponsorHRef${index}`} className='imageInputLabel'>Länk:</label>
            <input
              className='imageInputLabel'
              id={`sponsorHRef${index}`}
              type="text"
              value={sponsorHRefs[index]}
              onChange={(e) => { setSponsorHRefsAt(index, e.target.value); }}
            />
          </div>
        ))}
        <button style={{ marginTop: '20px' }} onClick={() => setSponsorImages([...sponsorImages, 0])}>Lägg till sponsor</button>
        <button style={{ marginTop: '20px' }} onClick={() => setSponsorImages(sponsorImages.slice(0, -1))}>Ta bort sponsor</button>
        <button style={{ marginTop: '20px' }} onClick={updateSponsorImages}>Spara</button>
      </div>
    </div>
  );
}

export default Images;